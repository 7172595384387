<!--
 * @Author: lisushuang
 * @Date: 2021-10-31 18:44:57
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 15:46:56
 * @Description: 公司概况
 * @FilePath: /dataview-viewer-test/src/views/company/info.vue
-->

<template>
  <div class="page scroller" v-loading="loading">
    <el-button type="primary" class="action-btn" @click="btnClick" style="float:right;margin-right:20px;width:80px">{{formDisabled ? '编辑':'提交'}}</el-button>
    <PcParser
      :displayData="showData"
      :form-data="formDesignData"
      ref="dialogForm"
      v-if="company_info_id && showData"
      @submit="onFormSubmit"
      :disabled="formDisabled"
      :moduleUuid="companyInfoObject"
      style="margin-top:10px;padding-right:20px"
    />
  </div>
</template>

<script>
import { dataInterface, transFieldsUUid } from "@/apis/data/index";
import PcParser from "@/custom-component/form/newParser/PcParser";
// // 公司概况 object_uuid
const companyInfoObject = "object6178c71cb0e16";
// // 公司概况 view_uuid
const companyInfoView = "view6178f761e8c9b";

export default {
  // 公司概况页面
  name: "company-info",
  components: { PcParser },
  data() {
    return {
      company_info_id: 0, // 公司概况的表id
      showData: null, // 表单展示的数据
      isEdit: true, // 是否在编辑中
      formDesignData: {}, // 表单自定义数据
      loading:true, // loading 状态
      formDisabled:true, // 表单是否禁止填报
      companyInfoObject
    };
  },
  methods: {
    /**
     * @description: 获取当前所在公司id
     * @param {*}
     * @return {*}
     */
    getNowCompanyId() {
      let id = this.$GetTargetArchi();
      if (id.archiType == "project") {
        id = id.company_id;
      } else {
        id = id.id;
      }
      return id;
    },

    /**
     * @description: 根据公司id查找概况表单id
     * @param {*} company_id
     * @return {*}
     */
    findCompanyInfoId(company_id) {
      if(!company_id){
        this.showErrorMsg('无效的数据，请稍后再试')
        return
      }
      dataInterface({
        __method_name__: "dataList",
        object_uuid: companyInfoObject,
        view_uuid: companyInfoView,
        transcode: 0,
        search: [
          {
            code: "company_id",
            ruleType: "eq",
            value: company_id,
          },
        ],
      })
        .then((res) => {
          if (res.data.code == 200) {
            // 有数据
            if (
              res.data.data.data.length > 0 &&
              res.data.data.data[0] &&
              res.data.data.data[0].id
            ) {
              this.company_info_id = res.data.data.data[0].id;
              this.initFormDesign()
            } else {
              // 没数据就要新增
              this.createNewCompanyInfo(company_id);
            }
          }
        })
        .catch(() => {
          this.showErrorMsg();
        });
    },

    /**
     * @description: 显示错误消息
     * @param {*} msg 显示的消息
     * @return {*}
     */
    showErrorMsg(msg = "请求数据失败，请稍后再试") {
      this.$message.error(msg);
    },

    /**
     * @description: 新增一个简单的公司概况信息
     * @param {*} company_id
     * @return {*}
     */
    createNewCompanyInfo(company_id) {
      dataInterface({
        __method_name__: "createData",
        object_uuid: companyInfoObject,
        transcode: 0,
        company_id: company_id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.company_info_id = res.data.data.id;
            this.initFormDesign()
          } else {
            this.showErrorMsg("新增数据失败");
          }
        })
        .catch(() => {
          this.showErrorMsg();
        });
    },

    /**
     * @description: 接收自定义表单抛出的事件
     * @param {*} formData 自定义表单抛出的已验证的数据
     * @return {*}
     */
    onFormSubmit(formData) {
      this.loading = true
      dataInterface({
        __method_name__:"updateData",
              data_id:this.company_info_id,
              object_uuid : companyInfoObject,
              ...formData
      }).then(res =>{
        this.loading = false
        this.formDisabled = true
        if(res.data.code == 200){
          this.$message.success('保存成功')
        }
      })
    },

    /**
     * @description: 初始化表单数据
     * @param {*}
     * @return {*}
     */
    initFormDesign() {
      transFieldsUUid(companyInfoObject).then((res) => {
        if (res.data.code == 200) {
            this.formDesignData = res.data.data;
            this.loading = false;
            dataInterface({
              __method_name__:"dataInfo",
              data_id:this.company_info_id,
              object_uuid : companyInfoObject,
              view_uuid:companyInfoView
            }).then(res => {
              this.showData = res.data.data
            })
        }
      });
    },

    /**
     * @description: 提交按钮点击，切换disable状态，看情况触发表单提交行为
     * @param {*}
     * @return {*}
     */
    btnClick(){
      if(this.formDisabled){
        this.formDisabled = false
      }else{
        this.$refs.dialogForm.handleSubmit()
      }
    }
  },
  mounted() {
    this.findCompanyInfoId(this.getNowCompanyId());
  },
};
</script>

<style lang="less" scoped>
.page{
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  // .action-btn{
  //   position: sticky;
  //   right: 15px;
  //   top: 10px;
  //   z-index: 10;
  // }
}
:deep(.page){
  .type-label {
		border-left: 3px solid var(--themeColor)!important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 0 0 16px;
		font-weight: 600;
	}
}
</style>
